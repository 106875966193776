 html {
	  font-size: 16px;
	  line-height: 1.3;
	   -moz-osx-font-smoothing: grayscale;

	  @media (min-width: 800px) {
	    font-size: 20px;
	  }
	}

 body{
 	font-family: $font-family-sans;
 	font-weight:normal;
 }

 h1, h2, h3{
 	font-family: $font-family-serif;
 	font-size: normal;
 }

 .content{

 	h1{
 		font-size: 60px;
 	}

 	h2{
 		font-size: 60px;
 		line-height: 65px;
 		padding:0 0 1em 0;
 	}

 	p{
 		font-size: 18px;
 		line-height: 24px;
 		color: white;
 	}
 }

 .section {
	 
	 .frage {
	 	text-align: center;
	 }

	 .antwort {
	 	text-align: left;
	 }

 }

