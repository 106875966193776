/* ----------- iPhone 5 and 5S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
  	
  	.block{
		width:290px;
		height:290px;
		line-height: 290px;
  	}

  	.leben{
	 	top:225px;
	 	left:82px;
        }

     .mobil{
   		top:225px;
	 	right:82px;
        }


     .haus{
     	top:10px;
        }

     #section1 .content h1{
     	width: 450px;
		height: 45px;
		background-size: 450px 45px;
		margin: 1em auto 1em auto;

     }

     .content .block p{
			font-size: 20px;
		}

	 #section1 .content .scroll-downs{
			top: 600px;
		}

	@keyframes scroll {
	  0% { opacity: 0; }
	  10% { transform: translateY(15px); opacity: 0; }
	  100% { transform: translateY(0px); opacity: 1;}
	}

	#topnavi{
		height: 80px;

		
	}

	#topnavi #home{
		width: 400px;
		height: 38px;	
		//border:1px solid white;    
		}

	#topnavi #home #Logo{
		width: 400px !important;
		height: 38px !important;
	}

	 #topnavi #kontakt a, #topnavi #facebook a{
		background-size: 101px 40px;
		height: 40px;
		margin-top:20px;
	}

	#topnavi #kontakt a{
		width: 55px;
	}

	#topnavi #facebook a{
	width: 46px;
	background-position: -55px center;
	}

	.pp-tableCell .antwort h2,
	.pp-tableCell .antwort p{
		width:70%;
		padding-left: 10%;
	}
	
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}



