$font-family-sans: "jaf-facitweb",Franklin Gothic Medium,Franklin Gothic,Helvetica,Arial,sans-serif;
$font-family-serif: "kinesis-pro-3",Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif;


$color-dgrau: #2b393f;
$color-rot: #ea6d59;
//$color-gelb: #ffc750;
$color-gelb: #F8CD65;
$color-gruen: #91ce9c;


