.cl:after {
    content:"";
    display:block;
    clear:both;
}

.bggrau{
	background-color: $color-dgrau;
	}

.bgrot{
	background-color: $color-rot;
	}


/* Sections */

	.section{
		color: white;
		text-align: center;

		.content{
			max-width:960px;
			margin: 0 auto;
		}
	}

	.pp-tableCell{
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position:right top;

		.antwort h2, .antwort p{
				width:50%;
				padding:30px;
				padding-left:0;

			}

		.antwort p{
				margin-right:20%;
				padding-top:0;
			}

		.haus{
			h2,p{
				background-color: $color-rot;
			}
		}

		.leben{
			h2,p{
				background-color: $color-gelb;
			}
		}

		.mobil{
			h2,p{
				background-color: $color-gruen;
			}
		}
	}

	#section8, #section1{ 
		.pp-tableCell p{
			background: transparent none;
			}
	}

	#section9{
		h1{
			color: $color-rot;
		}
		p{
			//padding:20px 20% 0 20%;

			strong{
				color: $color-gelb;
			}
		}
	}


	/* BG images
	 * --------------------------------------- */

	// Eine Heizsystem, dass von der Sonne inspiriert wurde: 
	#section3 .pp-tableCell{
		// background-image: url(http://placehold.it/500x750?text=Bild+Sonne);
		background-image: url(../images/wohnen-01.jpg);

	}
	// Drei Generationen unter einem Dach:
	#section5 .pp-tableCell{
		//background-image: url(http://placehold.it/500x750?text=Bild+Mehrgenerationen);
		background-image: url(../images/wohnen-02.jpg);
	}
	// Mit der richtigen Technologie können Sie sparen und schonen die Umwelt:
	#section7 .pp-tableCell{
		background-image: url(../images/wohnen-03.jpg);
	}

	// Wie kommt Papa nach seinem Unfall in die Dusche?
	#section9 .pp-tableCell{
		background-image: url(../images/leben-01.jpg);
	}

	//  Was ist, wenn er mal den Herd vergisst?
	#section11 .pp-tableCell{
		background-image: url(../images/leben-02.jpg);
	}

	//  Auto vom Dach betanken
	#section13 .pp-tableCell{
		background-image: url(../images/bewegen-01.jpg);
	}

	//  i:sy
	#section15 .pp-tableCell{
		background-image: url(../images/bewegen-02.jpg);
	}

	


	/* Icons
	 * --------------------------------------- */

	

	.frage h1:before{
	  padding-top:50px;
	  content: " ";
	  background-repeat: no-repeat;
	  background-size: 200px 70px;
	  position: relative;
	  display: block;
	  margin:0 auto;
	  top: 0;
	  background-position: 0 0;
	}

	#section2 .frage h1:before{
	  background-image: url('../img/icon-haus.svg');
	  width: 140px;
	  height: 70px;
	  background-size: 140px 70px;
	}

	#section4 .frage h1:before{
	  background-image: url('../img/icon-oma.svg');
	  background-size: 90px 95px;
	  width: 90px;
	  height: 95px;
	  padding-top:30px;
	}

	#section6 .frage h1:before{
	  background-image: url('../img/icon-energie.svg');
	  background-size: 90px 105px;
	  width: 90px;
	  height: 105px;
	}

	#section8 .frage h1:before{
	  background-image: url('../img/icon-papa.svg');
	  background-size: 75px 112px;
	  width: 75px;
	  height: 112px;
	}

	#section10 .frage h1:before{
	  background-image: url('../img/icon-opa.svg');
	  background-size: 115px 90px;
	  width: 115px;
	  height: 90px;
	}

	#section12 .frage h1:before{
	  background-image: url('../img/icon-batterie.svg');
	  background-size: 92px 100px;
	  width: 92px;
	  height: 100px;
	}

	#section14 .frage h1:before{
	  background-image: url('../img/icon-mobil.svg');
	  background-size: 140px 92px;
	  width: 140px;
	  height: 92px;
	}


	/* Intro (Section 1)
	 * --------------------------------------- */

	 #section1, #section17{
	 	.content{
	 		h1{
	 			width:350px;
	 			height: 15px;
	 			margin:0 auto 0 auto;
	 			background: url(../img/zukunftsbedarf-wohnen.svg) center center no-repeat;
	 			background-size: 350px 35px;

	 			text-indent:-999em;
	 		}

	 		.slogan{
				font-size:16px;
	 		}

	 		img{
	 			margin:0 0 2em 0;
	 		}

	 		.scroll-downs{
	 				top:420px;
	 			}

	 		.maus{
	 			//margin-top:400px;

	 			
	 		}

	 		
	 	}
	 }


	

	/* Mouse Icon
	 * --------------------------------------- */

	 .scroll-downs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  
  width :34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}


	/* Menü
	 * --------------------------------------- */

	 #menu, #topnavi{


	 	ul, li{
	 		margin:0;
	 		padding:0;
	 		list-style:none;
	 	}

	 	li{
	 		display: inline-block;
	 		float:left;
	 	}

	 	li a{
	 		display: block;
	 		font-size: 15px;
	 		letter-spacing:0.015em;
	 		color:#FFF;
	 		text-decoration: none;
	 		padding:0.5em 0.75em;
	 		float:left;
	 	}

	 	li a.grau{
	 		background-color: $color-dgrau;
	 	}

	 	li a.rot{
	 		background-color: $color-rot;
	 	}

	 	li a.gelb{
	 		background-color: $color-gelb;
	 	}

	 	li a.gruen{
	 		background-color: $color-gruen;
	 	}
	 }

	 #menu, .hide{
	 	display:none;
	 }


	 #topnavi{
	 	@extend .cl;
	 	position: absolute;
	 	top:0;
	 	width:100%;
	 	height:40px;
	 	background-color: rgba(43, 57, 63, 0.85);
	 	z-index: 999;

	 	li{display:block;}



	 	li a{
	 		text-indent:-999em;
	 		display: block;
	 	}

	 	.col1{
	 		float:left;	 
	 		width:50%;
	 	}

	 	.col2{

	 		width:50%;
	 		float:right;

	 		li{
	 			float:right;
	 			margin-top:5px;
	 		}
	 	}


	 	#home, #Logo{
	 		width:260px;
	 		height: 25px;
	 		text-indent:0em;
	 		//background: url(../img/zukunftsbedarf-wohnen.svg) center center no-repeat;
	 		//background-size: 250px 25px;		
	 	}

	 	#Logo{

	 		#zukunftsbedacht{fill:#FFFFFF;}
	 		#zukunftsbedacht-wohnen{fill:#EA6D59;}
	 		#zukunftsbedacht-leben{fill:#F8CD65;}
	 		#zukunftsbedacht-bewegen{fill:#91CE9C;}
	 		


	 	}

	 	#kontakt a, #facebook a{
	 		background: url(../img/topicons.svg) left center no-repeat;
	 		background-size: 63px 25px;
	 		height: 28px;
	 		padding: 0;
	 	}

	 	#kontakt{

	 	}

	 	#kontakt a{
	 		background-position:0 center;
	 		width: 35px;
	 	
	 	}

	 	#facebook{margin:5px;}

	 	#facebook a{
	 		background-position:-38px center;
	 		width: 25px;
	 	}

	 }

	 .pp-viewing-page2 #topnavi{
	 	display: block;
	 }


	 #kontakt{
	 	font-size: 15px;
	 	span{
	 		color: $color-gelb;
	 	}
	 }

	 

	/* Blöcke
	* --------------------------------------- */

	.section  .start,
	.section  .ende{
		margin-top:-300px;

		h1{
			padding-bottom:25px;
			margin-top:-100px;
		}
	 }

	 .section  .ende{
		
		h1{
			padding-bottom:50px;
		}
	 }


	 .block{
	 	display: inline-block;
	 	position: relative;

	 	width: 250px;
	 	height: 250px;
	 	line-height: 250px;

	 	color: #FFF;
	 	

    	&:before {                
            content: '';
            background-color: $color-rot;

            /* positioning / sizing */
            position: absolute;
            left:0;
            top:0;                                


            width: 100%;
            height: 100%;

            z-index: -1;

            /* transform before hovering */
            -ms-transform: rotate(45deg); /* IE 9 */
    	-webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    	transform: rotate(45deg);
        }



    	p{
    	text-align: center;
    	position: absolute;
    	width:100%;
  		top:45%;
		padding:0;
		margin:0;

    	
    	}

    	p.desc{
    		display: none;
    		padding-top:0;

    		strong{
    			display:inline-block;
    			padding-bottom:0.5em;
    		}
    	}

    	p.hl{
    		//display: none;
    	}

    	a.infos{
    		text-decoration: none;
    	}

    	a.infos:hover,
    	a.infos:active {
    		p.hl{
	   		display:none;
	    	}
    		p.desc{
    		top:25%;
    		display: block;
    		}
    	}


	 }



	 .leben{
	 	top:266px;
	 	left:70px;
			&:before {
				background-color: $color-gelb;
			}
        }

     .mobil{
   		top:266px;
	 	right:70px;
			&:before {
				background-color: $color-gruen;
			}
        }


     .haus{
     	top:80px;
     	//margin-top:-250px;
			
        }




	.ende{

		.block p{
			//padding-top:90px;
			font-size: 20px;
			background:transparent;
		}

		.leben{
		 	top:0;
		 	left:70px;
        }

        .mobil{
		 	top:0;
		 	right:70px;
        }

        .haus{
		 	top:186px;
		 	left:0;
        }

        .btn{
  
        	//border: 3px solid white;
        	padding-bottom:5px;
        	border-bottom:1px solid white;

        	color:white;
        	font-size:15px;
        	//font-weight:bold;
        }

        .slogan{
        	font-size:16px;
        }


	}

	
	

	/* Overwriting fullPage.js tooltip color
	* --------------------------------------- */
	#pp-nav.custom .pp-tooltip{
		color: #AAA;
	}
	#markup{
		display: block;
		width: 450px;
		margin: 20px auto;
		text-align: left;
	}


	#section16 p{
		margin-bottom:20px;

		a{
			color:#FFF;
			text-decoration: underline;
		}
	}


